import { GenUtils } from './../constants/GenUtils';
import QueryBaseService from "./query.base.service";
import Endpoints from "./endpoints";
import { MerchantInput } from "../types";
import { GridSortModel, GridFilterItem } from '@mui/x-data-grid';
import { IUser } from "../redux/models/user";

export type GoogleLoginParams = {
  google_id: string;
}

export type SignupParams = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  captchaToken: string;
}

export type RoleParams = {
  user: string;
  email?: string;
  merchant: string;
  role: string;
  id?: string;
}

export type BranchParams = {
    branch_name?: string;
    merchant_profile?: string;
    id?: string;
    users?: IUser[];
    is_active?: boolean;
}

export type ContactUsParams = {
  subject: string;
  message: string;
  captchaToken: string;
}

export type StatementParams = {
  storeID: string;
  start: Date;
  end: Date;
  format: 'pdf' | 'csv';
}

export type InvoiceParams = {
  storeID: string;
  start: Date;
  format: 'pdf';
}

class QueryService {
  // users queries

  static login(email: string, password: string) {
    const body = {
      email,
      password,
    };
    return QueryBaseService.post(Endpoints.LOGIN_URL, body);
  }

  static forgotPassword(code: string, password: string) {
    const body = {
      code,
      password
    };
    return QueryBaseService.post(`${Endpoints.ACCOUNTS}password/reset/verified/`, body);
  }

  static changePassword(body: {
    currentPassword: string,
    password: string
  }) {
    return QueryBaseService.post(`${Endpoints.ACCOUNTS}password/change/`, body);
  }

  static updatePassword(newPassword: string, oldPassword?: string) {
    return QueryBaseService.patch(`${Endpoints.ACCOUNTS}reset/password`, {
      oldPassword,
      newPassword,
    });
  }

  static logout() {
    return QueryBaseService.get(`${Endpoints.ACCOUNTS}logout/`);
  }

  static createProfile(body: SignupParams) {
    return QueryBaseService.post(`${Endpoints.ACCOUNTS}signup/`, body);
  }

  static fetchProfile() {
    return QueryBaseService.get(`${Endpoints.ACCOUNTS}users/me/`);
  }

  static forgotPasswordEmailConfirm(email: string) {
    return QueryBaseService.post(`${Endpoints.ACCOUNTS}password/reset/`, { email });
  }


  // dashboard queries

  static fetchUserDashboard() {
    return QueryBaseService.get(`${Endpoints.MERCHANT}`);
  }

  static fetchTransactions(id: string, type: 'SANDBOX' | 'PRODUCTION', page_size?: number, page?: number,
    filter?: GridFilterItem[], sort?: GridSortModel, search?: string, branch_id?: string, payment_link_id?: string) {
    return QueryBaseService.post(`store/transactions/${id}/${page && page > 1 ? '?page=' + page : ''}`, { type, page_size, filter, sort, search, branch_id, payment_link_id });
  }
  
  static fetchPayout(id: string, page_size?: number, page?: number,
    filter?: GridFilterItem[], sort?: GridSortModel, search?: string) {
    return QueryBaseService.post(`payouts/${id}/${page && page > 1 ? '?page=' + page : ''}`, { page_size, filter, sort, search });
  }

  static SignupVerify(token: string) {
    return QueryBaseService.get(`${Endpoints.ACCOUNTS}signup/verify?code=${token}`);
  }

  static fetchStatment(body: StatementParams) {
    return QueryBaseService.post(`accounting/merchant_statement/${body.storeID}`, {
      ...body, start: GenUtils.formatDate(body.start, 'yyyy-MM-DD'),
      end: GenUtils.formatDate(body.end, 'yyyy-MM-DD')
    }, { responseType: 'blob' });
  }

  static createTransaction(amount: string, merchantID: string, discount?: string, taxes?: string, fees?: string) {
    return QueryBaseService.post('transactions/', JSON.stringify({
          amount,
          merchantID,
          discount: discount != '' ? discount : undefined,
          taxes: taxes != '' ? taxes : undefined,
          fees: fees != '' ? fees : undefined,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Amwal': 'prod-amwal-' + merchantID
          },
          responseType: 'json'
        });
  }


  static fetchInvoice(body: InvoiceParams) {
    return QueryBaseService.post(`accounting/merchant_invoice/${body.storeID}`, {
      ...body, start: GenUtils.formatDate(body.start, 'yyyy-MM-DD')
    }, { responseType: 'blob' });
  }

  static fetchInvoices(id: string, page_size?: number, page?: number,
    filter?: GridFilterItem[], sort?: GridSortModel, search?: string) {
    return QueryBaseService.post(`accounting/invoices/${id}/${page && page > 1 ? '?page=' + page : ''}`, { page_size, filter, sort, search });
  }

  static PasswordResetVerify(token: string) {
    return QueryBaseService.get(`${Endpoints.ACCOUNTS}password/reset/verify?code=${token}`);
  }

  static async googleLogin(body: GoogleLoginParams) {
    return QueryBaseService.post(`${Endpoints.ACCOUNTS}google_auth/`, body);
  }

  static async getToken() {
    return QueryBaseService.get(`${Endpoints.ACCOUNTS}token/`, { withCredentials: true });
  }

  static intercomHash() {
    return QueryBaseService.get(`${Endpoints.ACCOUNTS}intercom_hash/`);
  }

  static addMembersToStore(body: RoleParams) {
    return QueryBaseService.post(`${Endpoints.ROLE}`, body);
  }

  static editMembersToStore(body: RoleParams) {
    return QueryBaseService.put(`${Endpoints.ROLE}${body.id}/`, body);
  }

  static getMembersOfStore(merchant: string) {
    return QueryBaseService.get(`${Endpoints.ROLE}?merchant=${merchant}`);
  }

  static searchMembersOfStore(merchant: string, search: string) {
    return QueryBaseService.get(`${Endpoints.ROLE}?merchant=${merchant}&&q=${search}`);
  }

  static deleteMembersFromStore(id: string) {
    return QueryBaseService.delete(`${Endpoints.ROLE}${id}/`);
  }

  static addBranchToStore(body: BranchParams) {
    return QueryBaseService.post(`${Endpoints.BRANCH}`, body);
  }

  static editBranchToStore(body: BranchParams) {
    return QueryBaseService.put(`${Endpoints.BRANCH}${body.id}/`, body);
  }
  static getBranchesOfStore(merchant: string) {
    return QueryBaseService.get(`${Endpoints.BRANCH}?merchant=${merchant}`);
  }

  static searchBranchesOfStore(merchant: string, search: string) {
    return QueryBaseService.get(`${Endpoints.BRANCH}?merchant=${merchant}&q=${search}`);
  }

  static deleteBranchFromStore(id: string) {
    return QueryBaseService.delete(`${Endpoints.BRANCH}${id}/`);
  }

  static createStore(body: {
    business_name: string,
    store_phone_number: string,
    store_email: string,
    national_id: string,
    store_country: string,
    store_category: string,
    captchaToken: string
  }) {
    return QueryBaseService.post(`${Endpoints.MERCHANT}`, body);
  }

  static updateStore(body: MerchantInput) {
    return QueryBaseService.put(`${Endpoints.MERCHANT}${body.id}/`, { ...body, store_phone_number: body.store_phone_number?.number ?? "" });
  }

  static fetchStore(id: string) {
    return QueryBaseService.get(`${Endpoints.MERCHANT}${id}/`);
  }

  static contactUs(body: ContactUsParams) {
    return QueryBaseService.post(`contact-us/`, body);
  }

  static fetchStatistics(form: { storeId?: string, start: Date|null, end: Date|null, type: string }) {
    const { storeId, start, end, type } = form; 
    let queryString = `${Endpoints.MERCHANTS}statistic/?type=${type}`;
    if (start) {
        queryString += `&start=${GenUtils.formatDate(start, 'yyyy-MM-DD')}T00:00:00`;
    }  
    if (end) {
        queryString += `&end=${GenUtils.formatDate(end, 'yyyy-MM-DD')}T23:59:59`;
    }
    if (storeId && storeId !== 'all') {
        queryString += `&storeId=${storeId}`;
    }
    return QueryBaseService.get(queryString);
}
  static refundTransaction(transactionID: string, refund_amount: number) {
    return QueryBaseService.post(`transactions/refund/${transactionID}/`, {refund_amount});
  }

  static fetchStorePaymentLinks(id: string, branchId?: string, page_size?: number, page?: number,
    filter?: GridFilterItem[], sort?: GridSortModel, search?: string) {
    const branchPath = branchId ? `${branchId}/` : '';
    const pagination = page && page > 1 ? `?page=${page}` : '';
    return QueryBaseService.post(`payment_links/${id}/${branchPath}${pagination}`, { page_size, filter, sort, search });
  }
  
  static createPaymentLink(merchantID: string, amount: string, productionKey: string, title: string, description: string, selectedDate: Date, singleUse: boolean, phoneNumber?: string, branch?: string) {
    return QueryBaseService.post(`payment_links/${merchantID}/create`, JSON.stringify({
          amount,
          merchantID,
          title,
          description,
          selectedDate,
          singleUse,
          phoneNumber,
          branch
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Amwal': 'prod-amwal-' + productionKey
          },
          responseType: 'json'
        });
  }

  static fetchPaymentLinkDetails(id: string) {
    return QueryBaseService.post(`payment_link/${id}/details`, {});
  }

}

export default QueryService;
